.tic-tac-toe-game-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  align-items: center;
  /* margin-top: 60px; */
  background-color: var(--main-background-color);
}

@media (max-height: 710px) {
  .tic-tac-toe-game-container {
    height: auto;
  }
}
