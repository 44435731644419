.main-light-saber-container {
  display: flex;
  width: 230px;
  justify-content: space-between;
  position: relative;
  left: -70px;
  top: 20px;
}

.lightsaber {
  position: relative;
  display: var(--lightsaber-active);
  rotate: 180deg;
}

#lightsaber1 {
  margin: 20px;
  rotate: 90deg;
}
#lightsaber2 {
  margin: 20px;
}

.lightsaber label {
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 88;
  text-indent: -9999px;
  width: 15px;
  height: 50px;
  border-bottom: solid 4px grey;
  border-top: solid 5px grey;
  border-radius: 5px;
  background: -webkit-linear-gradient(
    left,
    rgba(226, 226, 226, 1) 0%,
    rgba(219, 219, 219, 1) 50%,
    rgba(209, 209, 209, 1) 51%,
    rgba(254, 254, 254, 1) 100%
  );
}

.lightsaber .switch {
  background: #b94a37;
  width: 5px;
  height: 10px;
  display: block;
  position: absolute;
  bottom: 25px;
  left: 13px;
  transition: left 200ms;
}

.lightsaber input[type="checkbox"] {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
}

.lightsaber .plasma {
  transition: height 300ms;
  -moz-transition: height 300ms;
  -webkit-transition: height 300ms;
  -o-transition: height 300ms;
  -ms-transition: height 300ms;
  border-radius: 12px 12px 0 0;
  position: absolute;
  bottom: 55px;
  left: 2px;
  width: 10px;
  display: block;
  filter: blur(1px);
  height: 0;
}

.lightsaber input[type="checkbox"]:checked ~ div.plasma {
  height: 250px;
}

.lightsaber input[type="checkbox"]:hover ~ div.switch {
  background: #c09853;
  left: 12px;
}

.lightsaber input[type="checkbox"]:checked ~ div.switch {
  background: #468847;
}

.yoda {
  background: -webkit-linear-gradient(
    left,
    var(--main-color) 0%,
    rgb(254, 254, 254) 30%,
    rgb(254, 254, 254) 50%,
    rgb(254, 254, 254) 70%,
    var(--main-color) 100%
  );
}
