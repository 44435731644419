.game-result {
  color: white;
  /* font-family: "Yellowtail", cursive; */
  text-shadow: 0 0 3px magenta, 0 0 7px magenta, 0 0 15px black, 0 0 15px black;
}

.hangman-word {
  border-width: 1rem;
  border-bottom-style: solid;
  border-bottom-color: var(--main-color);
}

.hangman-word-text {
  display: flex;
  gap: 0.25em;
  font-size: 6rem;
  font-weight: bold;
  text-transform: uppercase;
  font-family: monospace;
}

.hangman-main-container {
  /* margin-top: 60px; */
  height: 100vh;
  background-color: var(--main-background-color);
}

.back-button {
  width: 100px;
  border: var(--main-color) solid 5px;
  padding: 5px;
  background-color: transparent;
  color: var(--main-color);
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
}

.back-button:hover {
  background-color: var(--main-color);
  color: black;
}

@media (max-width: 950px) {
  .hangman-main-container {
    /* margin-top: 60px; */
    height: 100%;
    padding: 10px;
  }
  .hangman-word {
    border-width: 5px;
    border-bottom-style: solid;
    border-bottom-color: var(--main-color);
  }
  .hangman-word-text {
    gap: 0.25em;
    font-size: 4rem;
    margin: 10px 0px 0px 0px;
  }
}

@media (max-width: 320px) {
  .hangman-word-text {
    font-size: 3rem;
  }
}
