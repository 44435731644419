.main-projects-container {
  font-family: monospace;
  font-size: 14px;
  // background-color: red;
  color: var(--main-text-color);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px 0px;
}

.single-project-title {
  font-size: 20px;
  margin-top: 0;
}

.main-single-project-card-container {
  background-color: var(--main-background-color);
  position: relative;
  transition: all 1s;
  margin: 30px 25px;
  width: 550px;
  &:after,
  &:before {
    content: " ";
    width: 100px;
    height: 100px;
    position: absolute;
    border: 0px solid #fff;
    transition: all 2s;
  }
  &:after {
    top: -23px;
    left: -23px;
    border-top: 10px solid var(--main-color);
    border-left: 10px solid var(--main-color);
  }
  &:before {
    bottom: -23px;
    right: -23px;
    border-bottom: 10px solid var(--main-color);
    border-right: 10px solid var(--main-color);
  }
  &:hover {
    scale: 1.05;
  }
  // &:hover {
  //   border-top-right-radius: 0px;
  //   border-bottom-left-radius: 0px;
  //   background: rgba(0, 0, 0, 1);
  //   color: white;
  //   &:before {
  //     width: 115%;
  //     height: 108%;
  //   }
  //   &:after {
  //     width: 115%;
  //     height: 108%;
  //   }
  // }
}

.OnDeskCoderSVG {
  position: absolute;
  width: 100px;
  fill: var(--main-color) !important;
  z-index: 100;
  right: 20px;
  bottom: 170px;
}

.single-project-text-para {
  font-size: 11px;
}

.single-project-image-container {
  // background-color: blue;
  display: flex;
  img {
    width: 70%;
  }
}

.project-underline {
  background-color: var(--main-color);
  margin: auto;
  height: 2px;
  width: 60px;
}

.tech-container {
  // background-color: green;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  align-items: center;
  // margin: 10px;
}

.tech-bubble {
  margin: 2px;
  font-size: 10px;
  font-weight: bolder;
  // background-color: black;
  border-style: solid;
  // border-color: var(--main-color);
  padding: 10px 10px;
  border-radius: 5px;
}

.single-project-text-container {
  padding: 20px;
}

@media (max-width: 490px) {
  .single-project-image-container {
    flex-direction: column;
    img {
      width: 100%;
    }
  }
}
@media (max-width: 355px) {
  #imageOfArthur {
    width: 200px;
    height: 280px;
  }
}
