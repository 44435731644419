.matching-app {
  max-width: 650px;
  margin: auto;
  color: var(--main-color);
  height: 100vh;
  text-align: center;
}

@media (max-height: 710px) {
  .matching-app {
    height: auto;
  }
}

.main-matching-app-container {
  background-color: var(--main-background-color);
}

/* Give the buttons a classname ************** */

button {
  background: none;
  border: 2px solid var(--main-color);
  padding: 6px 12px;
  border-radius: 4px;
  color: var(--main-color);
  font-weight: bold;
  cursor: pointer;
  font-size: 1em;
}

button:hover {
  background-color: var(--main-color);
  color: black;
}

.card-grid {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
}

@media (max-width: 690px) {
  .matching-app {
    max-width: 90%;
  }
}
