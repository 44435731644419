.HeroContainer {
  background-color: var(--hero-background-color);
  // background-color: red;
  color: var(--main-text-color);
  display: flex;
  height: 100vh;
  // padding-top: 50px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  // padding: 30px;
}

.HeroInnerContainer {
  z-index: 1000;
}

.glitch-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.glitch {
  position: relative;
  font-size: 80px;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
  letter-spacing: 5px;
  z-index: 1;
}

.glitch:before,
.glitch:after {
  display: block;
  content: attr(data-glitch);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
}

.glitch:before {
  animation: var(--glitch-animation-active1);
  color: #00ffff;
  z-index: -1;
}

.glitch:after {
  animation: var(--glitch-animation-active2);
  color: #ff00ff;
  z-index: -2;
}

@keyframes glitch-color {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translate(-2.5px, 2.5px);
  }

  40% {
    transform: translate(-2.5px, -2.5px);
  }

  60% {
    transform: translate(2.5px, 2.5px);
  }

  80% {
    transform: translate(2.5px, -2.5px);
  }

  to {
    transform: translate(0);
  }
}

.custom-shape-divider-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.svg-wrapper {
  position: relative;
  transition: transform ease;
}
.custom-shape-divider-bottom svg:nth-child(1) {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px; /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
  top: -10px;
}

#wave1 {
  top: -1px;
  animation-delay: 0s;
  animation-duration: 10s;
  width: 150%;
}
#wave2 {
  top: -150px;
  animation-delay: -10s;
  animation-duration: 15s;
  width: 150%;
}
#wave3 {
  top: -250px;
  animation-delay: -7s;
  animation-duration: 10s;
  width: 150%;
}
.parallax {
  animation: move-forever 1s linear infinite;
  // width: 5000px;
  transition: linear;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-200px, 0, 0);
  }
  25% {
    transform: translate3d(0px, 0, 0);
  }
  50% {
    transform: translate3d(0px, 0, 0);
  }
  75% {
    transform: translate3d(-300px, 0, 0);
  }
  100% {
    transform: translate3d(-200px, 0, 0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 768px) {
  #wave1 {
    top: -1px;
    animation-delay: -5s;
    animation-duration: 20s;
    width: 1000px;
  }
  #wave2 {
    top: -150px;
    animation-delay: -10s;
    animation-duration: 20s;
    width: 1000px;
  }
  #wave3 {
    top: -250px;
    animation-delay: -7s;
    animation-duration: 20s;
    width: 1000px;
  }
}

///////////////////////

.custom-shape-divider-bottom .shape-fill {
  fill: var(--skills-background-color);
}

.different-color-text {
  color: var(--main-color);
}
.hero-text {
  margin: 5px;
  // font-size: 50px;
}

.HeroInnerContainer {
  // border-style: solid;
  // background-color: red;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  flex-direction: column;
  // margin-top: 250px;
  justify-content: center;
  align-items: center;
}
.wrapper {
  /*This part is important for centering*/
  display: grid;
  place-items: center;
  flex-wrap: wrap;
}

.typing-demo {
  width: 87%;
  animation: typing 2s steps(22), blink 0.3s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 5px solid var(--main-color);
  font-family: monospace;
  font-size: 1.7em;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.hero-button {
  width: 250px;
  margin: 50px auto auto auto;
  border: solid 5px;
  padding: 10px;
  background-color: transparent;
  color: var(--main-color);
  font-weight: bold;
  font-size: 15px;
  // animation: float 5s infinite ease-in-out alternate;
}

.hero-button:hover {
  background-color: var(--main-color);
  color: black;
  border-color: var(--main-color);
}

@keyframes float {
  100% {
    transform: translateY(30px);
  }
}

#triangle-1 {
  position: absolute;
  fill: var(--skills-background-color);
  stroke-width: 3;
  stroke: var(--main-color);
  animation: float 1s infinite ease-in-out alternate;
}

.hero-svg {
  position: absolute;
  top: 43.5%;
  left: 30%;
  margin-top: -250px;
  z-index: 1;
  margin-left: -400px;
}

#Polygon-1,
#Polygon-2,
#Polygon-3,
#Polygon-4,
#Polygon-4,
#Polygon-5 {
  fill: var(--skills-background-color);
  stroke: var(--main-color);
  animation: float 1s infinite ease-in-out alternate;
}
#Polygon-2 {
  animation-delay: 0.2s;
}
#Polygon-3 {
  animation-delay: 0.4s;
}
#Polygon-4 {
  animation-delay: 0.6s;
}
#Polygon-5 {
  animation-delay: 0.8s;
}

// @media (max-width: 450px) {
//   svg {
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     margin-top: -250px;
//     margin-left: -190px;
//   }
// }

@media (max-width: 810px) {
  .hero-svg {
    display: none;
  }
}
@media (max-width: 490px) {
  .typing-demo {
    width: 61%;
    min-width: 290px;
    animation: typing 2s steps(22), blink 0.3s step-end infinite alternate;
    font-size: 15px;
  }
}
@media (max-width: 300px) {
  .typing-demo {
    width: 61%;
    min-width: 250px;
    animation: typing 2s steps(22), blink 0.3s step-end infinite alternate;
    font-size: 13px;
  }
}

@media (max-height: 420px) {
  .hero-button {
    display: none;
  }
  .hero-svg {
    display: none;
  }
}
