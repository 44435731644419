.score-board {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 20rem;
  font-size: 1.5em;
  background-color: var(--main-background-color);
  margin: 3rem auto;
  box-shadow: 0px 0px 8px #888;
  border-radius: 0.5rem;
  font-weight: bold;
}

.score {
  width: 100%;
  text-align: center;
  padding: 1rem 0;
}

.x-score {
  color: var(--main-color);
  border-bottom: 5px solid var(--main-color);
  border-radius: 0.5rem 0 0 0.5rem;
}
.o-score {
  color: var(--main-text-color);
  border-bottom: 5px solid var(--main-text-color);
  border-radius: 0 0.5rem 0.5rem 0;
}

.inactive {
  border-bottom: 5px solid transparent;
}
