.skillsContainer {
  background-color: var(--skills-background-color);
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.blog-card {
  display: flex;
  color: white;
  line-height: 1.4;
  justify-content: space-evenly;
  align-items: center;
  text-align: left;
  flex-wrap: wrap;
  padding: 2rem;
  width: 100%;
}

.about-me-description {
  width: 50%;
  padding-left: 50px;
  font-size: 16px;
  word-spacing: 2px;
  font-weight: 600;
  line-height: 1.9em;
  flex-direction: column;
  display: flex;
  justify-content: center;
  color: var(--main-text-color);
}
#about-me-title {
  font-size: 52px;
  line-height: 2.1;

  background: -webkit-linear-gradient(
    var(--main-color),
    var(--main-text-color)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#about-me-rectangle {
  background-color: var(--main-color);
  width: 5px;
  height: 30px;
}

#experenceRowOne {
  display: flex;
  margin-top: 150px;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
}
#experenceRowTwo {
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  justify-content: space-evenly;
}

.skillsSectionMainContainer {
  margin: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: start;
  width: 100%;
  #skillsBox {
    padding-bottom: 30px;
    flex-direction: column;
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: center;
    border-color: rgb(255, 255, 255);
    width: 100%;
  }
  #mainIndividualSkillsContainer {
    display: flex;
    margin: 20px 0;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;
  }
}

.skills-inner-layer {
  background: rgba(255, 0, 0, 0);
  backdrop-filter: blur(4px);
  position: relative;
  top: -80px;
  padding-top: 70px;
  z-index: 0;
}

#imageOfArthur {
  width: 300px;
  height: 400px;
  padding: 20px;
  opacity: 0.8;
}
.about-img-container {
  position: relative;
}
.about-img-container::before {
  border: 10px solid var(--main-color);
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  left: 10px;
  top: 10px;
}
.about-img-container::after {
  border: 10px solid var(--main-text-color);
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
  content: "";
  right: 10px;
  bottom: 15px;
}

.imgae-square {
  width: 400px;
  height: 400px;
}

.skills-img {
  z-index: 201;
  position: relative;
  transform: none;
  transition: z-index 10s ease-in;
}

.skillContainer {
  display: flex;
  width: 250px;
  margin: 5px;
  padding: 10px;
  color: white;
  background-color: #161616;
  position: relative;
  overflow: hidden;
  max-height: 80px;
  flex-direction: column;
  align-items: start;
  align-items: center;
  border-style: solid;
  border-color: black;
  box-shadow: 5px 5px 5px rgb(0, 0, 0);
  transform: none;
  transition: max-height 1s ease-out;
}

.skillContainer:has(.expand-button:checked) {
  max-height: 420px;
  transition: max-height 1s ease-out;
  .skills-img {
    z-index: 1;
    position: static;
    transition: position 10s ease-out;
  }
}

.expand-button {
  align-self: flex-end;
  appearance: none;
  background-color: var(--main-color);
  cursor: pointer;
  font: inherit;
  width: 1.15em;
  height: 0.8em;
  border: 0.4em solid var(--main-color);
  display: grid;
  transition: 0.5s transform ease-in-out;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}
.expand-button:checked {
  transform: rotate(180deg);
  transition: 0.5s transform ease-in-out;
}

.skill-logo-img {
  width: 70px;
}

@media (max-width: 1015px) {
  .blog-card {
    justify-content: space-between;
  }
  .about-me-description {
    margin: 10px;
    width: 46%;
  }
  .blog-card {
    width: 100%;
    padding: 0%;
  }
}
@media (max-width: 850px) {
  .blog-card {
    justify-content: center;
  }
  .about-me-description {
    width: 100%;
  }
}

.game-container {
  background-color: var(--skills-background-color) !important;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 60px;
}
.game-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 540px;
  line-height: 1.4em;
  color: white;
  .game-description-header {
    font-size: 30px;
    line-height: 1.4em;

    background: -webkit-linear-gradient(
      var(--main-color),
      var(--main-text-color)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  p {
    width: 80%;
    text-align: left;
    color: var(--main-text-color);
  }
}
.main-single-game-container {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  justify-content: center;
  align-items: center;
  column-gap: 10px;
  flex-wrap: wrap;
  width: 52%;
  line-height: 1.1em;
  grid-auto-rows: 300px;
  color: white;

  #box1 {
    align-self: flex-end;
    grid-column-start: 4;
    grid-column-end: 8;
  }
  #box2 {
    align-self: flex-start;
    grid-row-start: 2;
    grid-column-start: 2;
    grid-column-end: 6;
  }
  #box3 {
    align-self: flex-start;
    grid-row-start: 2;
    grid-column-start: 6;
    grid-column-end: 10;
  }
}

.single-game-card {
  background-color: var(--main-background-color);
  color: var(--main-text-color);
  // box-shadow: 0px 0px 10px var(--main-color);
  border-color: var(--main-color);
  // border-style: solid;
  // background-color: #161616;
  // box-shadow: 5px 5px 5px rgb(0, 0, 0);
  display: flex;
  align-items: center;
  border-style: solid;
  border-width: 0.5px;
  transition: all 1s ease;
  flex-direction: column;
  width: 300px;
  margin: 10px;
  padding: 20px;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 10px var(--main-color);
  }
  .single-game-text {
    font-size: 13px;
  }
  .single-game-header {
    align-self: center;
    font-size: 25px;
    font-family: monospace;
  }
  .single-game-line {
    background-color: white;
    height: 2px;
    margin-top: -15px;
    width: 150px;
  }
  .single-game-button {
    width: 100px;
    border: var(--main-color) solid 5px;
    padding: 5px;
    background-color: transparent;
    color: var(--main-color);
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
  }
  .single-game-button:hover {
    background-color: var(--main-color);
    color: black;
  }
}

.svg-wrapper2 {
  position: relative;
  width: 100%;
  top: -1px;
}
.custom-shape-divider-bottom2 {
  position: absolute;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  // transform: rotate(180deg);
}

.custom-shape-divider-bottom2 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 100px;
}

.custom-shape-divider-bottom2 .shape-fill {
  fill: var(--skills-background-color);
}

@media (max-width: 810px) {
  .main-single-game-container {
    display: flex;
    width: 80%;
  }
  .single-game-card {
    // box-shadow: 0px 0px 10px var(--main-color);
    border-color: var(--main-color);
    // border-style: solid;
    // background-color: #161616;
    // box-shadow: 5px 5px 5px rgb(0, 0, 0);
    display: flex;
    align-items: center;
    transition: all 1s ease;
    flex-direction: column;
    width: 80%;
    margin: 10px;
    padding: 20px;
  }
}

@media (max-width: 490px) {
  #imageOfArthur {
    width: 250px;
    height: 330px;
  }
  .main-single-game-container {
    width: 75%;
    margin-top: 20px;
  }
}
@media (max-width: 355px) {
  #imageOfArthur {
    width: 200px;
    height: 280px;
  }
}

@media (max-height: 500px) {
  #imageOfArthur {
    width: 150px;
    height: 200px;
  }
  .about-me-description {
    width: 50%;
    padding: 50px;
    font-size: 13px;
    word-spacing: 3px;
    line-height: 1.5em;
  }
  #about-me-title {
    font-size: 32px;
    line-height: 1.1;
    margin: auto;
  }
}
