.box {
  background-color: var(--main-background-color);
  border: none;
  border-radius: 10%;
  box-shadow: 0px 0px 8px #888888;
  width: 5rem;
  height: 5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 5em;
  font-family: "Fredoka", sans-serif;
  font-weight: bold;
  line-height: 5rem;
  margin: 0.5rem;
  /* transition: 0.3s; */
}

.x {
  color: var(--main-color);
}

.o {
  color: var(--main-text-color);
}

.box:hover {
  /* box-shadow: 0px 0px 15px #888888; */
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
