.HANGMAN_CONTAINER {
  position: relative;
  right: 44px;
  top: 20px;
}

.HEAD {
  width: 50px;
  z-index: 1001;
  height: 50px;
  border-radius: 100%;
  border: 10px solid white;
  position: absolute;
  top: 50px;
  right: -30px;
  box-shadow: 0 0 30px magenta, 0 0 7px magenta, 0 0 15px magenta,
    0 0 15px magenta inset;
}
.BODY {
  width: 10px;
  z-index: 1000;

  height: 100px;
  background-color: white;
  box-shadow: 0 0 3px magenta, 0 0 7px magenta, 0 0 15px magenta,
    0 0 15px magenta;
  position: absolute;
  top: 120px;
  right: 0;
}
.RIGHT_ARM {
  width: 100px;
  height: 10px;
  background-color: white;
  box-shadow: 0 0 3px magenta, 0 0 7px magenta, 0 0 15px magenta,
    0 0 15px magenta;
  position: absolute;
  top: 150px;
  right: -100px;
  rotate: -30deg;
}
.LEFT_ARM {
  width: 100px;
  height: 10px;
  background-color: white;
  box-shadow: 0 0 3px magenta, 0 0 7px magenta, 0 0 15px magenta,
    0 0 15px magenta;
  position: absolute;
  top: 150px;
  right: 10px;
  rotate: 30deg;
}
.RIGHT_LEG {
  width: 100px;
  height: 10px;
  background-color: white;
  box-shadow: 0 0 3px magenta, 0 0 7px magenta, 0 0 15px magenta,
    0 0 15px magenta;
  position: absolute;
  top: 210px;
  right: -90px;
  rotate: 60deg;
}
.LEFT_LEG {
  width: 100px;
  height: 10px;
  background-color: white;
  box-shadow: 0 0 3px magenta, 0 0 7px magenta, 0 0 15px magenta,
    0 0 15px magenta;
  position: absolute;
  top: 210px;
  right: 0;
  rotate: -60deg;
}

.ROPE {
  height: 50px;
  width: 10px;
  background-color: var(--main-color);
  box-shadow: 0 0 3px magenta, 0 0 7px magenta, 0 0 15px magenta,
    0 0 15px magenta;
  margin-left: 120px;
  position: absolute;
  top: 0;
  right: 0;
}

.SUPPORT_BEAM {
  height: 10px;
  width: 200px;
  background-color: var(--main-color);
  box-shadow: 0 0 3px magenta, 0 0 7px magenta, 0 0 15px magenta,
    0 0 15px magenta;
  margin-left: 120px;
}

.MAIN_BEAM {
  height: 400px;
  width: 10px;
  background-color: var(--main-color);
  box-shadow: 0 0 3px magenta, 0 0 7px magenta, 0 0 15px magenta,
    0 0 15px magenta;
  margin-left: 120px;
}

.FLOOR_SUPPORT {
  height: 10px;
  width: 250px;
  background-color: var(--main-color);
  box-shadow: 0 0 3px magenta, 0 0 7px magenta, 0 0 15px magenta,
    0 0 15px magenta;
}

@media (max-width: 950px) {
  .HEAD {
    width: 30px;
    height: 30px;
    border: 5px solid white;
    right: -20px;
  }
  .BODY {
    width: 5px;
    height: 70px;
    top: 90px;
    right: -2px;
  }
  .RIGHT_ARM {
    width: 50px;
    height: 5px;
    top: 120px;
    right: -55px;
  }
  .LEFT_ARM {
    width: 50px;
    height: 5px;
    top: 120px;
    right: 5px;
  }
  .RIGHT_LEG {
    width: 50px;
    height: 5px;
    top: 150px;
    right: -50px;
  }
  .LEFT_LEG {
    width: 50px;
    height: 5px;
    top: 150px;
    right: 0;
  }

  .ROPE {
    height: 50px;
    width: 5px;
  }

  .SUPPORT_BEAM {
    height: 5px;
    width: 200px;
    margin-left: 70px;
  }

  .MAIN_BEAM {
    height: 250px;
    width: 5px;
    margin-left: 70px;
  }

  .FLOOR_SUPPORT {
    height: 5px;
    width: 150px;
  }
}
