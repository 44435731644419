.reset-button {
  border: none;
  border-radius: 0.5rem;
  color: var(--main-color);
  border-color: var(--main-color);
  border-style: solid;
  font-size: 2rem;
  padding: 0.5rem;
  margin: 2rem auto;
  display: block;
  transition: 0.5s;
  cursor: pointer;
}

.reset-button:hover {
  background-color: var(--main-color);
  color: black;
}
