.btn {
  width: 100%;
  border: 3px solid white;
  background: none;
  aspect-ratio: 1/1;
  font-size: 2.5rem;
  box-shadow: 0 0 3px magenta, 0 0 7px magenta, 0 0 15px magenta,
    0 0 15px magenta inset;
  text-transform: uppercase;
  padding: 0.5rem;
  font-weight: bold;
  cursor: pointer;
  color: rgb(255, 255, 255);
  text-shadow: 0 0 3px magenta, 0 0 7px magenta, 0 0 15px rgb(0, 0, 0),
    0 0 15px black;
}

.btn.active {
  background-color: rgb(0, 255, 255);
  color: rgb(255, 255, 255);
  text-shadow: 0 0 10px black, 0 0 10px black, 0 0 150px rgb(0, 0, 0);
}
.btn.inactive {
  opacity: 0.2;
}

.btn:hover:not(:disabled),
.btn:focus:not(:disabled) {
  background-color: var(--main-color);
}

@media (max-width: 950px) {
  .btn {
    width: 80%;
    margin: auto;
    border: 1px solid white;
    font-size: 2rem;
  }
}
