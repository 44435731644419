.socialLogoImg {
  width: 50px;
  height: auto;
}

.nav-name-container {
  width: 40%;
  font-size: 20px;
  h3 {
    margin: 0;
  }
}

.nav-home-underline {
  background-color: var(--main-color);
  width: 70px;
  height: 5px;
}

.navbar {
  // box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.364);
  font-family: Satoshi, "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: fixed;
  width: 100%;
  color: white;
  height: 60px;
  z-index: 10000;
  background: #050505;
  display: flex;
  align-items: center;
  .tabsContainer {
    // border-style: solid;
    display: flex;
    width: 60%;
    align-items: center;
    justify-content: space-evenly;
  }
  .socialContainer {
    // background-color: yellow;
    // border-style: solid;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .buttonContainer {
    // background-color: purple;
    // border-style: solid;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
  .navLinks {
    text-decoration: none;
    display: flex;
    color: white;
    flex-direction: column;
    justify-content: center;
    h4 {
      margin: 5px;
    }
  }
  .navLinks:visited {
    color: white;
  }
}

.nav-h4 {
  font-size: 15px;
}
