div.track,
div.bar {
  box-sizing: border-box;
  position: relative;
}

div.progress {
  margin: 25px auto;
  width: 190px;
  padding-left: 2px;
  padding-right: 2px;
  height: 21px;
  border-radius: 5px;
  border: solid 3px #5a5959;
  background-color: #1e1e1e;
  // position: absolute;
  top: calc(50% - 13px);
  left: calc(50% - 53px);
}

div.track {
  position: relative;
  width: 100%;
  height: 21px;
  overflow: hidden;
  // -webkit-animation: prgBar 5s linear 0s infinite alternate;
  // animation: prgBar 5s linear 0s infinite alternate;
}

div.bar {
  height: 15px;
  width: 6px;
  background-color: var(--main-color);
  position: relative;
  border-radius: 2px;
  box-shadow: 0px 0.5px 5px #00ff11;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 100px;
  top: 3px;
  float: left;
  clear: top;
}

// @-webkit-keyframes prgBar {
//   0% {
//     width: 0%;
//   }
//   9.99% {
//     width: 0%;
//   }
//   10% {
//     width: 10%;
//   }
//   95% {
//     width: 100%;
//   }
// }

// @keyframes prgBar {
//   0% {
//     width: 0%;
//   }
//   9.99% {
//     width: 0%;
//   }
//   10% {
//     width: 10%;
//   }
//   95% {
//     width: 100%;
//   }
// }
