.connect-container {
  padding: auto;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  background-color: var(--resumeEmail-background-color);
  height: 100vh;
  // max-height: 95vh;
  .connect-header {
    scroll-snap-align: start;
    position: relative;
    top: 150px;
    font-size: 32px;
    background: -webkit-linear-gradient(
      var(--main-color),
      var(--main-text-color)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.example {
  display: flex;
  justify-content: center;
  align-items: center;
}

// $vw-width: 37vw;
$vw-width: 450px;

// $vw-width: calc(60vw - 50px);
$vw-height: calc(#{$vw-width} / (1 / 1));
$tod-tri: var(--main-color);
$gold-tri: black;

.shadow {
  animation: flicker 01s infinite alternate;
  filter: drop-shadow(1px 1px 10px $tod-tri)
    drop-shadow(-1px -1px 10px $tod-tri);
}

.triangle-wrapper {
  position: relative;
  top: -40px;
  z-index: -1;
  width: $vw-width;
  // width: 900px;
  height: $vw-height;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background-color: $tod-tri;
  // background-color: red;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(180deg);
}

.triangle {
  // width: calc(900px - 40px);
  width: calc($vw-width - 30px);
  height: calc(#{$vw-width} - 30px);
  // background-color: hsl(210, 50%, 14%);
  background-color: $gold-tri;
  // background-color: blue;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  margin-top: 10px;
  filter: blur(15px) drop-shadow(-10px -10px 75px $tod-tri);
  p {
    color: #fff;
  }
}

@keyframes flicker {
  25% {
    filter: none;
  }
}

.connect-socials-card {
  background-color: rgba(0, 0, 0, 0.694);
  backdrop-filter: blur(10px);
  position: relative;
  font-size: 15px;
  top: 220px;
  z-index: 10;
  width: 400px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 3px;
  border-color: var(--main-color);
  box-shadow: -10px -10px 75px $tod-tri;
}

.socials-img-container {
  display: flex;
  margin-bottom: 5px;
  margin-top: -10px;
  justify-content: space-evenly;
  width: 100%;
}
.social-img {
  width: 50px;
}

.connect-email-card {
  background-color: rgba(0, 0, 0, 0.694);
  backdrop-filter: blur(10px);
  position: relative;
  font-size: 15px;
  top: 250px;
  z-index: 10;
  width: 300px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-width: 3px;
  border-color: var(--main-color);
  box-shadow: -10px -10px 75px $tod-tri;
}
.email-btn2 {
  appearance: none;
  width: 120px;
  margin: -5px 0px 10px 0px;
  border: var(--main-color) solid 5px;
  padding: 5px;
  display: flex;
  background-color: transparent;
  color: var(--main-color);
  font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  justify-content: center;
}
.email-btn2:hover {
  background-color: var(--main-color);
  color: black;
}

@media (max-width: 500px) {
  .connect-container {
    height: 110vh;
  }
  .triangle-wrapper {
    width: 400px;
    animation: none;
    filter: none;
    height: 400px;
  }
  .shadow {
    animation: none;
  }
  .triangle {
    animation: none;
    width: calc(400px - 30px);
    height: calc(400px - 30px);
  }
  .connect-socials-card {
    width: 300px;
  }
  .connect-email-card {
    width: 200px;
  }
}
@media (max-width: 400px) {
  .triangle-wrapper {
    width: 350px;
    height: 350px;
  }

  .triangle {
    width: calc(350px - 30px);
    height: calc(350px - 30px);
  }
  .connect-socials-card {
    width: 300px;
  }
  .connect-email-card {
    width: 200px;
  }
}

@media (max-width: 290px) {
  .connect-container {
    height: 110vh;
  }
  .triangle-wrapper {
    margin-top: 50px;
    width: 250px;
    height: 250px;
  }

  .triangle {
    width: calc(250px - 30px);
    height: calc(250px - 30px);
  }
  .connect-socials-card {
    width: 200px;
    top: 180px;
    font-size: 10px;
  }
  .connect-email-card {
    width: 150px;
    top: 200px;
    font-size: 10px;
  }
  .social-img {
    width: 30px;
  }
  .email-btn2 {
    width: 70px;
    border: var(--main-color) solid 2px;
    padding: 1px;
    font-size: 12px;
  }
}

@media (max-height: 710px) {
  // .connect-container {
  //   height: auto;
  // }
  .triangle-wrapper {
    animation: none;
  }
  .shadow {
    animation: none;
  }
  .triangle {
    animation: none;
  }
}

@media (max-width: 1500px) {
  .connect-container {
    height: 110vh;
  }
}
