.modal-container {
  background-color: #161616;
  z-index: 100;
  width: 90%;
  max-width: 600px;
  color: black;
  box-shadow: 5px 5px 5px rgb(0, 0, 0);
}

.modal-text-container {
  color: white;
  margin: auto;
  width: 75%;
}
.close-modal-button-container {
  width: 100%;
  justify-content: start;
  color: white;
  display: flex;
}
.close-modal-button {
  position: relative;
  cursor: pointer;
  right: -20px;
}

.message {
  color: white;
  border: solid rgb(3, 232, 3);
  background-color: black;
  padding: 10px;
  margin-top: 20px;
  font-size: 14px;
}

.modal-overlay {
  width: 100%;
  // margin-top: 10px;
  height: 120vh;
  // min-height: 800px;
  position: absolute;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.316);
}

form {
  box-sizing: border-box;
  max-width: 100%;
  width: 420px;
  padding: 0 15px;
  margin: 15px auto 20px;
}

.form-group {
  margin-top: 1.6rem;
  .form-field {
    border: 1px solid darken(#d8dbe8, 5%);
  }

  .form-field,
  .form-submit {
    color: inherit;
    display: block;
    position: relative;
    z-index: 1;
    font-family: monospace;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    background: white;
    padding: 12px 15px;
    min-height: 3rem;
  }
  .form-submit {
    width: 140px;
    border: var(--main-color) solid 5px;
    padding: 5px;
    display: flex;
    background-color: transparent;
    color: var(--main-color);
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: var(--main-color);
      color: black;
    }
  }
}

@media (max-width: 290px) {
  .modal-container {
    background-color: #161616;
    z-index: 100;
    width: 90%;
    max-width: 600px;
    color: black;
    font-size: 12px;
    box-shadow: 5px 5px 5px rgb(0, 0, 0);
  }

  .modal-text-container {
    color: white;
    margin: auto;
    width: 75%;
  }
  .close-modal-button-container {
    width: 100%;
    justify-content: start;
    color: white;
    display: flex;
  }
  .close-modal-button {
    position: relative;
    cursor: pointer;
    right: -20px;
  }

  .message {
    color: white;
    border: solid rgb(3, 232, 3);
    background-color: black;
    padding: 10px;
    margin-top: 20px;
    font-size: 14px;
  }

  .modal-overlay {
    width: 100%;
    // margin-top: 10px;
    height: 120vh;
    // min-height: 800px;
    position: absolute;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.316);
  }

  form {
    box-sizing: border-box;
    max-width: 100%;
    width: 420px;
    padding: 0 15px;
    margin: 15px auto 20px;
  }

  .form-group {
    margin-top: 1rem;
    .form-field {
      border: 1px solid darken(#d8dbe8, 5%);
    }

    .form-field,
    .form-submit {
      color: inherit;
      display: block;
      position: relative;
      z-index: 1;
      font-family: monospace;
      width: 100%;
      height: 10px;
      box-sizing: border-box;
      border-radius: 4px;
      background: white;
      padding: 15px 15px;
      min-height: 3rem;
    }
    .form-submit {
      width: 140px;
      padding: 5px;
      display: flex;
      background-color: transparent;
      color: var(--main-color);
      font-weight: bold;
      font-size: 15px;
      cursor: pointer;
      justify-content: center;
      align-items: center;
    }
  }
}

// @media (max-width: 900px) {
//   .modal-overlay {
//     width: 100%;
//     // margin-top: 10px;
//     height: 120vh;
//     // min-height: 800px;
//     position: relative;
//     bottom: 500px;
//     z-index: 1000;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     // background-color: rgba(255, 255, 255, 0.316);
//     background-color: red;
//   }
// }
// @media (max-width: 900px) {
//   .modal-overlay {
//     width: 100%;
//     // margin-top: 10px;
//     height: 120vh;
//     // min-height: 800px;
//     position: relative;
//     bottom: 500px;
//     z-index: 1000;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     // background-color: rgba(255, 255, 255, 0.316);
//     background-color: red;
//   }
// }
