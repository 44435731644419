.nav,
.nav-body,
.menu {
  display: flex;
  justify-content: end;
  align-items: center;
}

.nav-body {
  position: fixed;
  margin-top: 5px;
  margin-left: -13px;
  width: 100%;
  z-index: 10000;
}

.nav {
  position: relative;
  background-color: transparent;
  padding: 5px;
  transition: 0.5s;
  border-radius: 50px;
  overflow: hidden;
  // box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.menu {
  margin: 0;
  padding: 0;
  width: 0;
  overflow: hidden;
  transition: 0.5s;
}

.nav input:checked ~ .menu {
  width: 300px;
}
.nav:has(input:checked) {
  background-color: var(--main-background-color);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
}

.menu li {
  list-style: none;
  margin: 0 19px;
}

.menu li a {
  text-decoration: none;
  color: var(--main-text-color);
  text-transform: uppercase;
  font-weight: 600;
  transition: 0.5s;
}

.navLinks {
  text-decoration: none;
}

.menu li a:hover {
  color: var(--main-color);
}
.menu li a:visited {
  color: var(--main-text-color);
  text-decoration: none;
}

.nav input {
  width: 40px;
  height: 40px;
  cursor: pointer;
  opacity: 0;
}

.nav span {
  position: absolute;
  left: 15px;
  width: 30px;
  height: 3px;
  border-radius: 50px;
  background-color: var(--main-text-color);
  pointer-events: none;
  transition: 0.5s;
}

.nav input:checked ~ span {
  background-color: var(--main-color);
}

.nav span:nth-child(2) {
  transform: translateY(-8px);
}

.nav input:checked ~ span:nth-child(2) {
  transform: translateY(0) rotate(-45deg);
}
.nav span:nth-child(3) {
  transform: translateY(8px);
}

.nav input:checked ~ span:nth-child(3) {
  transform: translateY(0) rotate(45deg);
}
.nav input:checked ~ span:nth-child(4) {
  transform: translateY(0) rotate(45deg);
}

@media (max-width: 370px) {
  .nav input:checked ~ .menu {
    width: 210px;
  }
  .nav {
    border-radius: 10px;
    padding: 5px;
  }
  .menu li {
    list-style: none;
    margin: 0 10px;
    font-size: 14px;
  }
}
