:root {
  /*Defualt Cyan App Theme*/
  --main-color: cyan;
  --main-background-color: #050505;
  --main-text-color: white;
  --hero-background-color: #262626;
  --skills-background-color: #121212;
  --resumeEmail-background-color: black;
  --project-card-box-shadow: rgba(0, 0, 0, 1) 20px 20px 10px;
  --single-project-card-border: rgb(3, 255, 255);
  --glitch-animation-active1: glitch-color 0.3s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
  --glitch-animation-active2: glitch-color 0.3s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
  --lightsaber-active: none;
}

[data-theme="orange"] {
  --main-color: rgb(255, 89, 0);
  --main-background-color: #050505;
  --main-text-color: white;
  --hero-background-color: #262626;
  --skills-background-color: #121212;
  --resumeEmail-background-color: black;
  --project-card-box-shadow: rgba(0, 0, 0, 1) 5px 10px 10px;
  --single-project-card-border: ;
  --glitch-animation-active1: glitch-color 0.3s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
  --glitch-animation-active2: glitch-color 0.3s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
  --lightsaber-active: none;
}
[data-theme="green"] {
  --main-color: rgb(0, 255, 0);
  --main-background-color: #050505;
  --main-text-color: white;
  --hero-background-color: #262626;
  --skills-background-color: #121212;
  --resumeEmail-background-color: black;
  --project-card-box-shadow: rgba(0, 0, 0, 1) 5px 10px 10px;
  --single-project-card-border: ;
  --glitch-animation-active1: glitch-color 0.3s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
  --glitch-animation-active2: glitch-color 0.3s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
  --lightsaber-active: none;
}
[data-theme="purple"] {
  --main-color: rgb(242, 0, 255);
  --main-background-color: #050505;
  --main-text-color: white;
  --hero-background-color: #262626;
  --skills-background-color: #121212;
  --resumeEmail-background-color: black;
  --project-card-box-shadow: rgba(0, 0, 0, 1) 5px 10px 10px;
  --single-project-card-border: ;
  --glitch-animation-active1: glitch-color 0.3s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
  --glitch-animation-active2: glitch-color 0.3s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
  --lightsaber-active: none;
}
[data-theme="sith"] {
  --main-color: red;
  --main-background-color: #050505;
  --main-text-color: white;
  --hero-background-color: black;
  --skills-background-color: black;
  --resumeEmail-background-color: black;
  --project-card-box-shadow: red -1px -1px 10px;
  --single-project-card-border: ;
  --glitch-animation-active1: none;
  --glitch-animation-active2: none;
  --lightsaber-active: ;
}
[data-theme="jedi"] {
  --main-color: blue;
  --main-background-color: white;
  --main-text-color: black;
  --hero-background-color: rgb(183, 183, 183);
  --skills-background-color: white;
  --resumeEmail-background-color: rgb(183, 183, 183);
  --project-card-box-shadow: blue -1px -1px 10px;
  --single-project-card-border: ;
  --glitch-animation-active1: none;
  --glitch-animation-active2: none;
  --lightsaber-active: ;
}

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
}

body {
  background-color: var(--main-background-color);
}

.App {
  text-align: center;
  overflow: hidden;
  /* CHECK WHY I NEEDED overflow: HIDDEN; */
}

.theme-container {
  display: flex;
  justify-content: end;
  transition: 0.5s;
}

.themeSwitcher {
  transition: 0.5s;
  position: fixed;
  display: flex;
  z-index: 10000;
  overflow: hidden;
  /* width: 50px; */
  height: 40px;
  flex-direction: column;
  color: var(--main-color);
  margin-top: 65px;
  margin-right: 5px;
  align-items: center;
  /* background-color: var(--background-color); */
  padding: 15px;
  width: 40px;
  font-size: 13px;
  font-weight: bold;
  /* border-style: solid; */
  border-width: 2px;
  border-color: black;
  border-radius: 50px;
  transition: height 0.5s linear;
}

.themeSwitcher:has(.theme-button:checked) {
  height: 290px;
  transition: 0.5s;
  background-color: var(--main-background-color);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.5);
}

.inner-theme-contaier {
  display: flex;
  transition: 0.5s;
  margin-top: 80px;
  flex-direction: column;
  justify-content: space-evenly;
  /* height: 300px; */
  align-items: center;
}

.theme-button {
  appearance: none;
  position: absolute;
  transition: transform 0.5s linear;
}
.theme-button::after {
  appearance: none;
  cursor: pointer;
  content: "☼";
  border-radius: 100px;
  width: 60px;
  height: 60px;
  color: var(--main-text-color);
  /* color: red; */
  position: relative;
  text-align: center;
  align-content: center;
  font-size: 35px;
  transition: transform 0.5s linear;
  transition: 0.5s;
}
.theme-button::after:has(.theme-button:checked) {
  color: var(--main-color);
}

.theme-button:checked {
  transform: scale(1.4);
  transition: transform 0.5s linear;
}

.themeButton {
  margin: 10px 0px;
  border-radius: 100px;
  height: 28px;
  width: min-content;
  border-color: black;
  align-content: center;
  transition: 0.5s;
}

#cyan {
  background-color: cyan;
  margin: 2px;
}
#orange {
  background-color: rgb(255, 89, 0);
  margin: 2px;
}
#green {
  background-color: rgb(0, 255, 0);
  margin: 2px;
}
#purple {
  background-color: rgb(242, 0, 255);
  margin: 2px;
}
#sith {
  background-color: black;
  border-color: white;
  color: red;
  height: auto;
  margin: 2px;
}
#jedi {
  background-color: white;
  border-color: black;
  color: blue;
  height: auto;
  margin: 2px;
}
